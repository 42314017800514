import React, { useEffect, useState } from "react";
import moment from "moment";
import http from "./http";
import { Button, Col, Row, Image } from "antd";

interface Data {
  text: string;
  targetTime: string;
  url: string | null;
}

interface timeData {
  day: string;
  hour: string;
  min: string;
  sec: string;
}

const Home: React.FC = () => {
  return (
    <div className="site-layout-content">
      <img
        src="my-image.png"
        alt="logo"
        className="main-img"
      />
      <p className="large-text">第八届挺好萌已结束</p>
      <Image src={`group/image10.jpg`} alt='比赛结果' style={{ width: '100%', maxWidth: '400px', height: 'auto' }}/>
      <Button
        type="primary"
        size="large"
        href="https://tieba.baidu.com/p/9118211587"
        className="main-btn"
      >
        点我前往第八届挺好萌结果汇总楼
      </Button>
      <Button
        type="primary"
        size="large"
        href="https://tieba.baidu.com/p/9145540457"
        className="main-btn"
      >
        点我前往第八届挺好萌长颈鹿表彰楼
      </Button>
    </div>
  );
};

export default Home;
