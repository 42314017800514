import axios from "axios";
import { message } from "antd";

const http = () => {
  const options = {
    baseURL: `/api`,
    headers: {
      "Content-Type": "application/json"
    }
  };
  const instance = axios.create(options);
  instance.interceptors.response.use((res) => {
    return res;
  }, (err) => {
    message.error(`呃呃，服务器好像出错了`);
    return Promise.reject(err);
  });
  return instance;
};

export default http;
