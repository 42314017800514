import React, { useEffect, useState } from "react";
import http from "./http";
import VotePage, { VoteData } from "./VotePage";

const Vote: React.FC = () => {
  const [data, setData] = useState<VoteData | null>(null);

  useEffect(() => {
    http().get(`/vote`).then((res) => {
      setData(res.data);
    }).catch((err) => console.error(err));
  }, []);

  return (
    <VotePage data={data} />
  );
};

export default Vote;
