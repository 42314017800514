import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';

const { Content } = Layout;

const Group: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<number>(1);

  const handleMenuClick = (key: string) => {
    setSelectedImage(Number(key));
  };

  const numberToDesc = (i: number) => {
    if (i <= 8) {
      return `第${i}天海选`;
    } else if (i === 9) {
      return `复活赛`;
    } else {
      return `淘汰赛`;
    }
  };

  return (
    <Layout>
      <Sider collapsible breakpoint="lg" >
        <Menu theme='dark' onClick={(e) => handleMenuClick(e.key)} style={{ padding: '20px'}} defaultSelectedKeys={[selectedImage.toString()]}>
          {Array.from({ length: 10 }, (_, i) => (
            <Menu.Item key={i + 1} icon={`${i+1} `}>{numberToDesc(i + 1)}</Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Content style={{ padding: '20px', textAlign: 'center', backgroundColor: '#38b', display: 'flex', flexDirection: 'column', alignItems: "center", }}>
        <img src={`group/image${selectedImage}.jpg`} alt={numberToDesc(selectedImage)} style={{ width: '80%', height: 'auto' }} />
      </Content>
    </Layout>
  );
};

export default Group;