import { Menu } from "antd";
import React from "react";
import { useLocation, Link } from "react-router-dom";
import {
  HomeOutlined,
  UnorderedListOutlined,
  ClockCircleOutlined,
  StopOutlined,
  OrderedListOutlined,
  BarChartOutlined,
  HeartOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";

const MenuComp: React.FC = () => {
  const history = useLocation();
  return (
    <Menu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={[history.pathname]}
    >
      <Menu.Item key="/" icon={<HomeOutlined />}>
        <Link to="/">主页</Link>
      </Menu.Item>
      {/* <Menu.Item key="/vote" icon={<ClockCircleOutlined />}>
        <Link to="/vote">报票</Link>
      </Menu.Item> */}
      <Menu.Item key="/introduction" icon={<QuestionCircleOutlined />}>
        <Link to="/introduction">介绍</Link>
      </Menu.Item>
      <Menu.Item key="/result" icon={<BarChartOutlined />}>
        <Link to="/result">比赛结果</Link>
      </Menu.Item>
      <Menu.Item key="/list" icon={<UnorderedListOutlined />}>
        <Link to="/list">提名列表</Link>
      </Menu.Item>
      <Menu.Item key="/group" icon={<OrderedListOutlined />}>
        <Link to="/group">分组情况</Link>
      </Menu.Item>
      <Menu.Item key="/forbid" icon={<StopOutlined />}>
        <Link to="/forbid">禁赛列表</Link>
      </Menu.Item>
      <Menu.Item key="/donate" icon={<HeartOutlined />}>
        <Link to="/donate">捐助</Link>
      </Menu.Item>
    </Menu>
  );
};

export default MenuComp;
