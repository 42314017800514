import React from "react";
import { Layout } from "antd";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Menu from "./Menu";
import Home from "./Home";
import List from "./List";
import Vote from "./Vote";
import Forbid from "./Forbid";
import Group from "./Group";
import Result from "./Result";
import Donate from "./Donate";
import Introduction from "./Introduction";

const { Header, Content } = Layout;

const App: React.FC = () => {
  return (
    <Router>
      <Layout style={{ minHeight: "100vh", backgroundColor: "#38b" }}>
        <Header>
          <div className="logo">
            <img src="zhanba.jpg" alt="Logo" height="50px" />
          </div>
          <Menu />
        </Header>
        <Content className="main-cotent">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/list" element={<List />} />
            <Route path="/vote" element={<Vote />} />
            <Route path="/introduction" element={<Introduction />} />
            <Route path="/result" element={<Result />} />
            <Route path="/forbid" element={<Forbid />} />
            <Route path="/group" element={<Group />} />
            <Route path="/donate" element={<Donate />} />
          </Routes>
        </Content>
        <footer style={{color: "#ffffff", backgroundColor: "#001529" }}>
        <div className="footer-content">
            <p>でも好きな誰かを独り占めするよりも、好きな人が好きな人を自分も好きになって、そうやって人の輪が広がっていくほうが……なんか、いいじゃない?</p>
          </div>
        </footer>
      </Layout>
    </Router>
  );
};

export default App;
