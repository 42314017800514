import { Space, Card, Table, Skeleton, Flex } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import "./Table.css";

export interface VoteData {
  name: string;
  time: string;
  voter: number;
  last_time: string;
  last_floor: string;
  votes: VoteInfo[];
}

export interface VoteInfo {
  group: number;
  rank: number;
  name: string;
  vote: number;
  rate_global: number;
  rate_group: number;
}

const VotePage: React.FC<{data: VoteData | null}> = ({data}) => {
  const columns: ColumnsType<VoteInfo> = [
    {
      title: "组别",
      dataIndex: "group",
      key: "group",
      align: "center",
      responsive: ["lg"]
    },
    {
      title: "排名",
      dataIndex: "rank",
      key: "rank",
      align: "center",
    },
    {
      title: "选手",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "票数",
      dataIndex: "vote",
      key: "vote",
      align: "center",
    },
    {
      title: "得票率 (%)",
      dataIndex: "rate_global",
      key: "rate_global",
      align: "center",
    },
    {
      title: "组内得票率 (%)",
      dataIndex: "rate_group",
      key: "rate_group",
      align: "center",
      responsive: ["lg"]
    },
  ];

  const getRowClassName = (record: VoteInfo, data: VoteData) => {
    if (data.name.startsWith("海选赛")) {
      if (record.rank <= 5) {
        return "color-qualified";
      } else if (record.rank <= 10) {
        return "color-playoff";
      } else {
        return "white";
      }
    } else if (data.name.startsWith("复活赛")) {
      if (record.rank <= 8) {
        return "color-qualified";
      } else {
        return "white";
      }
    } else {
      if (record.rank == 1) {
        return "color-qualified";
      } else {
        return "white";
      }
    }
  }

  return (
    <Content style={{ padding: "20px", backgroundColor: "#38b" }}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {data === null ? (
          <Skeleton active />
        ) : (
          <>
            <Title level={2} className="vote-title">
              {data.name}
            </Title>
            <Flex wrap justify="center" gap="small">
              <Card title="计票时间" size="small" className="table-card">
                {data.time}
              </Card>
              <Card title="投票人数" size="small" className="table-card">
                {data.voter}
              </Card>
              <Card title="最近投票时间" size="small" className="table-card">
                {data.last_time}
              </Card>
              <Card title="最近投票楼层" size="small" className="table-card">
                {data.last_floor}
              </Card>
            </Flex>
            <Table
              rowClassName={(record, _) => getRowClassName(record, data)}
              dataSource={data.votes}
              columns={columns}
              bordered
              pagination={false}
            />
          </>
        )}
      </Space>
    </Content>
  );
};

export default VotePage;
