import React, { useEffect, useState } from "react";
import { Select } from "antd";
import http from "./http";
import VotePage, { VoteData } from "./VotePage";

const { Option } = Select;

const Result: React.FC = () => {
  const [options, setOptions] = useState<string[]>([]);
  const [data, setData] = useState<VoteData | null>(null);

  useEffect(() => {
    http()
      .get("/results.json")
      .then((response) => {
        setOptions(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSelectChange = (key: string) => {
    http()
      .get(`/result/${key}.json`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <Select
          style={{ width: '60%', marginBottom: 20 }}
          placeholder="选择日期"
          onChange={handleSelectChange}
        >
          {options.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </div>
      {
        data ?
        <VotePage data={data} /> :
        <div />
      }
    </>
  );
};

export default Result;
