import React from 'react';
import './Introduction.css';

const Introduction: React.FC = () => {
return (
    <div style={{ padding: '20px', textAlign: 'center' }} className='inroduction-main'>
        <p className="title-text">提名规则</p>
        <div className="rule-container">
            <ol className="rule-list inroduction-main-text">
                <li>
                    表情必须是皱眉笑。（注意，这里的“挺好脸”定义仅作为“挺好萌”入场券的标准，不代表其他环境下的“挺好脸”标准）
                </li>
                <li>
                    作品必须已经日本动画化并开播（包括TV动画、剧场版动画、OVA动画、网络播放或其他方式配信的动画、动画短篇、动画广告、有官方日语配音播出的他国或合作动画等；
                    游戏CG、配音漫画等非动画形式，以及同人图、里番、同人动画等非官方来源不在此列）。角色必须是动画中出现过的角色，但截图可以来自游戏、漫画、小说插画、官方宣传图等一切官方渠道的图片。
                    若截图不是动画中的，请说明一下其出处并截一张动画中该角色出现的场景。
                    禁止以XX妈妈、XX姐姐等亲属关系为名提名。<strong>禁止P图，一旦发现将立即取消参赛资格。</strong>
                </li>
                <li>
                    虽然投票有等级限制（战吧10级或以上），但是提名没有哦！
                </li>
            </ol>
            <img src="ting.jpg" alt="" />
        </div>
        <p className="title-text">挺萌的元素</p>
        <div className="container">
            <div className="large-character title-text">挺</div>
            <div className="description">
                <p className='inroduction-main-text'>一般是指角色在追求自己的目标过程中，经历了求而不得、精彩表演、最终释怀的过程，而精彩表演的震撼程度往往决定角色的最终挺力。</p>
            </div>
        </div>
        <div className="container">
            <div className="large-character title-text">重</div>
            <div className="description">
                <p className='inroduction-main-text'>重是一种性格和身心状态，常指角色具有沉重的感情，在人际关系中对一些寻常的事情过分执着和看重。具有沉重属性的萌娘通称重女。沉重的程度常用重力来表示。</p>
            </div>
        </div>
        <div className="container">
            <div className="large-character title-text">惨</div>
            <div className="description">
                <p className='inroduction-main-text'>在挺好萌语境中，惨有两种情况，一是角色遭受的客观生理折磨，二是指角色对所执着的人或物求而不得，或情感、事业发展与预期不符的状态。例如，具有败犬属性的角色经常也包含惨元素。</p>
            </div>
        </div>
        {/* <div className="image-wall">
            <div className="image-wall-scroll">
                <img src="my-image.png" alt="" />
                <img src="my-image.png" alt="" />
            </div>
        </div> */}
    </div>
);
};

export default Introduction;
