import React, { useEffect, useState } from 'react';
import { Table, Input, Flex, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import http from './http';

const { Search } = Input;

interface DataType {
  name: string;
  type: 1 | 2 | 3;
}

const Forbid: React.FC = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    http().get(`/forbid.json`).then((res) => {
      setData(res.data);
    }).catch((err) => console.error(err));
  }, []);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const filteredData = data
    .filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const columns: ColumnsType<DataType> = [
    {
      title: '类别',
      dataIndex: 'type',
      key: 'type',
      filters: [
        { text: '一类', value: 1 },
        { text: '二类', value: 2 },
        { text: '三类', value: 3 },
      ],
      onFilter: (value, record) => record.type === value,
      sorter: (a, b) => a.type - b.type,
      render: (_, record, __) => {
        return `${'零一二三'[record.type]}类`
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: '选手',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend',
    },
  ];

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '16px' }}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Flex wrap gap="small" justify="center">
          <Search
            placeholder="搜索"
            onSearch={handleSearch}
          />
        </Flex>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ defaultPageSize: 20 }}
          size="small"
        />
      </Space>
    </div>
  );
};

export default Forbid;