import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Image } from 'antd';
import http from './http';

const Donate: React.FC = () => {
  const [donors, setDonors] = useState<string[]>([]);

  useEffect(() => {
    http().get(`/donate.json`).then((res) => {
      setDonors(res.data);
    }).catch((err) => console.error(err));
  }, []);
  
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <p className="large-text">捐助</p>
      <p className="fix-text">挺好萌长期由各路战吧吧友用爱发电，今年挺好萌开设网页并准备抽奖奖品的同时运营成本陡增，因此我们考虑接受不高于挺好萌运维成本的捐助。</p>
      <p className="fix-text">就算是没有足够的捐助，挺好萌也是足够运营下去的，还请不要担心。捐助将全部用于挺好萌的后续运营与开发。</p>
      <p className="fix-text"><strong>本届挺好萌比赛已经结束，我们将暂停接受赞助。</strong></p>
      {/* <Row justify="center" gutter={16}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Image src="donate/zfb.jpg" alt="支付宝" style={{ width: '100%', maxWidth: '200px', height: 'auto' }} />
        </Col>
      </Row> */}
      <p className="large-text">捐助者名单</p>
      <Row gutter={[16, 8]}>
        {donors.map((donor, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <Card size="small" bordered={false}>{donor}</Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Donate;
