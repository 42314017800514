import React, { useEffect, useState } from "react";
import { Card, List, Input, Select, Pagination, Image, Flex } from "antd";
import http from "./http";
import "./List.css";

const { Search } = Input;
const { Option } = Select;

interface ImageData {
  name: string;
  type: "auto" | "kuroe" | "default";
}

const ListPage: React.FC = () => {
  const [data, setData] = useState<ImageData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(40);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"升序" | "降序">("升序");
  const [filterType, setFilterType] = useState<
    "auto" | "kuroe" | "default" | null
  >(null);

  useEffect(() => {
    http()
      .get("/list.json")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handleSortChange = (value: "升序" | "降序") => {
    setSortOrder(value);
    setCurrentPage(1);
  };

  const handleFilterChange = (value: "auto" | "kuroe" | "default" | "all") => {
    setFilterType(value === "all" ? null : value);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const filteredData = data
    .filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((item) => (filterType === null ? true : item.type === filterType))
    .sort((a, b) => {
      if (sortOrder === "升序") {
        return a.name.localeCompare(b.name);
      }
      return b.name.localeCompare(a.name);
    });

  const paginatedData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div style={{ margin: "0 auto", padding: "16px" }}>
      <Flex wrap gap="small" justify="center" style={{ marginBottom: "16px" }}>
        <Search
          placeholder="搜索"
          onSearch={handleSearch}
          style={{ maxWidth: 560 }}
        />
        <Select defaultValue="升序" onChange={handleSortChange}>
          <Option value="升序">升序</Option>
          <Option value="降序">降序</Option>
        </Select>
        <Select
          defaultValue="all"
          onChange={handleFilterChange}
          style={{ width: 100 }}
        >
          <Option value="all">全部</Option>
          <Option value="auto">自动提名</Option>
          <Option value="kuroe">黑江规则</Option>
          <Option value="default">正常提名</Option>
        </Select>
      </Flex>
      <List
        itemLayout="horizontal"
        grid={{ gutter: 8, xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }}
        dataSource={paginatedData}
        size="large"
        renderItem={(item) => (
          <List.Item key={item.name}>
            <Card
              className="list-card"
              title={
                <Image
                  src={`avatar/${item.type}/${item.name}.jpg`}
                  alt={item.name}
                  style={{ maxWidth: "100px" }}
                />
              }
            >
              <Card.Meta
                className="list-card-meta"
                title={item.name.split("-")[1]}
                description={item.name.split("-")[0]}
              />
            </Card>
          </List.Item>
        )}
      />
      <Pagination
        align="center"
        pageSizeOptions={[40, 80, 120, 200]}
        current={currentPage}
        pageSize={pageSize}
        total={filteredData.length}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default ListPage;
